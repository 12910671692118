
import { defineComponent, reactive, toRefs, computed } from "vue";
import Tabs from "@/components/atomics/Tabs.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { formatDate } from "@/lib/utility/dateTime";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import { downloadZipFile } from "@/lib/utility/downloadFile";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useList from "@/lib/compositional-logic/useList";
import { SimpleBuiltInTemplate } from "@/models/Template";
import ConfirmDeleteTemplatePopup from "@/components/popups/ConfirmPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import { AnonymousFunction } from "@/models/Function";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";
import useAccount from "@/lib/compositional-logic/useAccount";

export default defineComponent({
  name: "TemplateList",
  components: {
    Tabs,
    FlatButton,
    ValidationTextField,
    ToggleCheckbox,
    AfterLoginPage,
    ListItemActionDropdown,
    ConfirmDeleteTemplatePopup,
    Vue3Popper,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const { openNotification } = useNotificationControl();
    const { popupState, openPopup } = usePopupControl();
    const { permissions } = useAccount();

    const {
      getTemplateList,
      deleteTemplate,
      publishTemplate,
      unPublishTemplate,
    } = RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const unPublishAction = async (id: string) => {
      const response = await unPublishTemplate(id);
      publishReloadList();
      unPublishReloadList();
      if (response) openNotification();
    };

    const publishAction = async (id: string) => {
      const response = await publishTemplate(id);
      unPublishReloadList();
      publishReloadList();
      if (response) openNotification();
    };

    const state = reactive({
      currentTabIndex: 0,
      listKeyWordPublished: "",
      listKeyWordUnPublished: "",
      templateToDelete: {
        id: "",
        title: t("template.deleteTemplate"),
        message: "",
        action: AnonymousFunction,
      },
    });

    const actionDropdown = (
      hasDeleteBtn: boolean,
      entry: SimpleBuiltInTemplate
    ) => [
      {
        action: () => {
          editTemplate(entry.id);
        },
        icon: "icons/edit.svg",
        text: t("common.edit"),
        disabled: permissions.templateManagement != 1,
      },
      {
        action: () => {
          downloadZipFile(
            `${process.env.VUE_APP_API_CLOUD_URL}/${entry.zipFilePath}`
          );
        },
        icon: "icons/download.svg",
        text: t("template.downloadOpt"),
        disabled: false,
      },
      ...(hasDeleteBtn
        ? [
            {
              action: async () => {
                state.templateToDelete.id = entry.id;
                state.templateToDelete.message = t("template.deleteMessage", {
                  name: entry.title,
                });
                state.templateToDelete.action = deleteTemplate;
                openPopup("confirmAction");
              },
              icon: "icons/delete.svg",
              text: t("template.deleteOpt"),
              disabled: permissions.templateManagement != 1,
              isDelete: true,
            },
          ]
        : []),
    ];

    const {
      list: publishedTemplateList,
      totalLength: totalPublishedTemplate,
      setFilterKeyword: setPublishedKeyword,
      reloadList: publishReloadList,
    } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getTemplateList({ pageSize }),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getTemplateList({ page, pageSize, keyword }),
      filterList: async ({ keyword, pageSize }) =>
        await getTemplateList({ keyword, pageSize }),
    });

    const {
      list: unPublishedTemplateList,
      totalLength: totalUnPublishedTemplate,
      setFilterKeyword: setUnPublishedKeyword,
      reloadList: unPublishReloadList,
    } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getTemplateList({ unpublished: true, pageSize }),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getTemplateList({ unpublished: true, page, pageSize, keyword }),
      filterList: async ({ keyword, pageSize }) =>
        await getTemplateList({ unpublished: true, keyword, pageSize }),
    });

    const tabTags = computed(() => [
      { name: t("template.publishedTab"), total: totalPublishedTemplate.value },
      {
        name: t("template.unpublishedTab"),
        total: totalUnPublishedTemplate.value,
      },
    ]);

    const editTemplate = (id: string) => {
      router.push({ name: "TemplateFileAndInfo", params: { id } });
    };

    const addTemplate = () => {
      router.push({ name: "TemplateFileAndInfo" });
    };

    const onDeleteDone = () => {
      unPublishReloadList();
      openNotification({ text: t("notification.templateDeleted") });
    };

    return {
      ...toRefs(state),
      actionDropdown,
      unPublishAction,
      publishAction,
      addTemplate,
      formatDate,
      tabTags,
      publishedTemplateList,
      unPublishedTemplateList,
      setPublishedKeyword,
      setUnPublishedKeyword,
      popupState,
      onDeleteDone,
      permissions,
    };
  },
});
