import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09c31018"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { class: "publish-status" }
const _hoisted_3 = { class: "template-title" }
const _hoisted_4 = { class: "template-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_Vue3Popper = _resolveComponent("Vue3Popper")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_ConfirmDeleteTemplatePopup = _resolveComponent("ConfirmDeleteTemplatePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, null, {
      "top-part": _withCtx(() => [
        _createVNode(_component_Tabs, {
          "onCurrentTab:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTabIndex = $event)),
          "tab-tags": _ctx.tabTags
        }, null, 8, ["tab-tags"])
      ]),
      "right-part": _withCtx(() => [
        _createVNode(_component_FlatButton, {
          onOnClick: _ctx.addTemplate,
          text: _ctx.$t('template.addButton'),
          icon: require('icons/add-white.svg'),
          class: "add-btn",
          enabled: _ctx.permissions.templateManagement == 1
        }, null, 8, ["onOnClick", "text", "icon", "enabled"])
      ]),
      "left-top-part": _withCtx(() => [
        _withDirectives(_createVNode(_component_ValidationTextField, {
          "prefix-icon": require('icons/search-placeholder.svg'),
          "suffix-icon": require('icons/close.svg'),
          placeholder: _ctx.$t('template.searchHint'),
          value: _ctx.listKeyWordPublished,
          "onUpdate:value": _ctx.setPublishedKeyword,
          "is-delete-on-suffix": ""
        }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "value", "onUpdate:value"]), [
          [_vShow, _ctx.currentTabIndex == 0]
        ]),
        _withDirectives(_createVNode(_component_ValidationTextField, {
          "prefix-icon": require('icons/search-placeholder.svg'),
          "suffix-icon": require('icons/close.svg'),
          placeholder: _ctx.$t('template.searchHint'),
          value: _ctx.listKeyWordUnPublished,
          "onUpdate:value": _ctx.setUnPublishedKeyword,
          "is-delete-on-suffix": ""
        }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "value", "onUpdate:value"]), [
          [_vShow, _ctx.currentTabIndex == 1]
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("template.publishedTitle")), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("template.publish_status")), 1)
        ])
      ]),
      "left-bottom-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.publishedTemplateList, (entry) => {
              return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                key: entry.id,
                "head-icon": require('icons/digitalsign-template.svg'),
                actions: _ctx.actionDropdown(false, entry),
                "has-divider": ""
              }, {
                "title-primary": _withCtx(() => [
                  _createVNode(_component_Vue3Popper, {
                    content: entry.title
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_3, _toDisplayString(entry.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ]),
                "title-secondary": _withCtx(() => [
                  _createTextVNode(_toDisplayString(`最終更新日 ${_ctx.formatDate(entry.updatedAt, "YYYY/MM/DD")}`), 1)
                ]),
                body: _withCtx(() => [
                  _createVNode(_component_ToggleCheckbox, {
                    class: "checkbox",
                    name: "unPublishTemplate",
                    disabled: _ctx.permissions.templateManagement != 1,
                    checked: entry.isPublished,
                    onOnChange: ($event: any) => (_ctx.unPublishAction(entry.id))
                  }, null, 8, ["disabled", "checked", "onOnChange"])
                ]),
                _: 2
              }, 1032, ["head-icon", "actions"]))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.unPublishedTemplateList, (entry) => {
              return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
                key: entry.id,
                "head-icon": require('icons/digitalsign-template.svg'),
                actions: _ctx.actionDropdown(true, entry),
                "has-divider": ""
              }, {
                "title-primary": _withCtx(() => [
                  _createVNode(_component_Vue3Popper, {
                    content: entry.title
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(entry.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ]),
                "title-secondary": _withCtx(() => [
                  _createTextVNode(_toDisplayString(`最終更新日 ${_ctx.formatDate(entry.updatedAt, "YYYY/MM/DD")}`), 1)
                ]),
                body: _withCtx(() => [
                  _createVNode(_component_ToggleCheckbox, {
                    class: "checkbox",
                    name: "publishTemplate",
                    disabled: _ctx.permissions.templateManagement != 1,
                    checked: entry.isPublished,
                    onOnChange: ($event: any) => (_ctx.publishAction(entry.id))
                  }, null, 8, ["disabled", "checked", "onOnChange"])
                ]),
                _: 2
              }, 1032, ["head-icon", "actions"]))
            }), 128))
      ]),
      _: 1
    }),
    (_ctx.popupState.confirmAction)
      ? (_openBlock(), _createBlock(_component_ConfirmDeleteTemplatePopup, _mergeProps({ key: 0 }, _ctx.templateToDelete, { onOnDone: _ctx.onDeleteDone }), null, 16, ["onOnDone"]))
      : _createCommentVNode("", true)
  ], 64))
}